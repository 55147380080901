import request from "../utils/request"


export function getLinkList(data) {
    return request({
        url: '/admin/spider/link/list',
        method: 'post',
        data
    })
}

export function addLink(data) {
    return request({
        url: '/admin/spider/link/add',
        method: 'post',
        data
    })
}

export function updateLink(data) {
    return request({
        url: '/admin/spider/link/update',
        method: 'post',
        data
    })
}

export function delLink(data) {
    return request({
        url: '/admin/spider/link/del',
        method: 'post',
        data
    })
}
